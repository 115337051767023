#headers {
    .content{
        margin-top: none;
        text-align: center;
        margin-bottom: 50px;
    }
}

.subscribe-line{
    .input-group{
        margin-bottom: 15px;
    }
    .card {
        margin-top: 15px;
    }
}

.magazine-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        max-width: 260px;
        min-width: 260px;
        min-height: 372px;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 0px;
    }
}

// Preloader
#preloader{
    background-color: #1F5DA5;
}

// Navbar
.navbar{
    background-color: #1F5DA5 !important;
}
.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
    color: #FFF;
}
.navbar-translate {
    .img{
        max-width: 150px;
    }
}

// Navbar mobile
.sidebar-collapse .navbar-collapse:before {
    background-color: #1F5DA5 !important;
    .nav-item a {
        color: white !important;
    }
}

// Footer
.ccra-logo{
    max-width: 160px;
    padding: 15px;
    opacity: 0.8;
}

// Header
.page-header{
    display: flex;
    justify-content: center;
    align-items: center;
    .title{
        padding-top: 0px;
    }
    .description {
        font-weight: 400;
    }
    .category{
        font-size: 14px;
        font-weight: 600;
    }
}
.page-header:before {
    background-color: rgba(0, 0, 0, 0.45);
}

//

// About US

.about-us-styles{
    .about-us-description{
        margin-bottom: 60px;
    }
}
.text-area-styles{
    margin-top:30px;
}

// Articles
.article-img{
    background-position: center;
    background-size: cover;
    display: block;
    height: 240px;
    width: 100%;
}

// Article
.article-styles {
    .title{
        margin-bottom: 0px;
    }
    .subtitle{
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .image-description {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 30px;
        margin-top: 15px;
    }
    p {
        white-space:pre-wrap;
    }
    .video-styles{
        width: 100%;
        margin-top: 30px;
    }
}

// ADS
.mobile-pub {
    display: none!important;
}

// Responsive

@media screen and (max-width: 991px){
    .sidebar-collapse .navbar-collapse:before {
      background: #FFF;
    }
    .nav-item a{
      color: black;
    }
    .navbar-collapse{
      background-color: #1F5DA5;
    }
}
@media screen and (max-width: 768px){
    .title{
        font-size: 30px;
    }
}
@media screen and (max-width: 500px){
    .mobile-pub {
        display: block!important;
    }
    .desktop-pub {
        display: none!important;
    }
}

// Mailchimp
.custom-mailchimp-form{
    input{
        background-color: transparent;
        border-radius: 30px;
        border: 1px solid #E3E3E3;
        box-shadow: none;
        color: #2c2c2c;
        font-size: 0.8571em;
        height: auto;
        line-height: normal;
        margin-right: 2%;
        min-width: 50%;
        padding: 10px 18px 10px 18px;
        transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
    button{
        background-color: #1F5DA5;
        border-radius: 30px !important;
        border-width: 1px;
        color: #FFFFFF;
        border-width: 1px;
        border-radius: 30px !important;
        padding: 11px 23px;
        margin: 2%;
    }
}

// Carrousel
.custom-carousel {
    .carousel {
    text-align: center;
    position: relative;
    opacity: 0;
    margin: 0 auto;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    }
    .carousel .carousel-container-inner {
    margin: 0 auto;
    position: relative;
    }
    .carousel .carousel-viewport {
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    }
    .carousel .carousel-arrow-default {
    border: 3px solid !important;
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.9);
    height: 32px;
    width: 32px;
    font-weight: 900;
    background: rgba(0, 0, 0, 0.15);
    }
    .carousel .carousel-arrow {
    position: absolute;
    z-index: 1;
    bottom: 23px;
    padding: 0;
    cursor: pointer;
    border: none;
    }
    .carousel .carousel-arrow:focus {
    outline: none;
    }
    .carousel .carousel-arrow:before {
    font-size: 19px;
    display: block;
    margin-top: -2px;
    }
    .carousel .carousel-arrow:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    }
    .carousel .carousel-left-arrow {
    left: 23px;
    }
    .carousel .carousel-right-arrow {
    right: 23px;
    }
    .carousel .carousel-left-arrow.carousel-arrow-default:before {
    content: '<';
    padding-right: 2px;
    }
    .carousel .carousel-right-arrow.carousel-arrow-default:before {
    content: '>';
    padding-left: 2px;
    }
    .carousel .carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    -ms-touch-action: pan-y pinch-zoom;
        touch-action: pan-y pinch-zoom;
    }
    .carousel .carousel-track .carousel-slide {
    display: inline-block;
    opacity: 0.7;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    white-space: initial;
    }
    .carousel .carousel-track .carousel-slide > * {
    display: block;
    }
    .carousel .carousel-track .carousel-slide.carousel-slide-loading {
    background: rgba(204, 204, 204, 0.7);
    }
    .carousel .carousel-track .carousel-slide.carousel-slide-fade {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    opacity: 0;
    }
    .carousel .carousel-track .carousel-slide.carousel-slide-selected {
    opacity: 1;
    z-index: 1;
    }
    .carousel.loaded {
    opacity: 1;
    }
    .carousel .carousel-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    text-align: center;
    }
    .carousel .carousel-dots li {
    display: inline-block;
    }
    .carousel .carousel-dots button {
    border: 0;
    background: transparent;
    font-size: 1.1em;
    cursor: pointer;
    color: #CCC;
    padding-left: 6px;
    padding-right: 6px;
    }
    .carousel .carousel-dots button.selected {
    color: black;
    }
    .carousel .carousel-dots button:focus {
    outline: none;
    }
}
